import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Button, ButtonRow } from 'hudl-uniform-ui-components';
import buttonRowPreviewData from '../../../../data/previews/button-row.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Button Row"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Buttons"
        tierThree="Button Row"
      />

      <PageNavigation>
        <PageNavigationLink>Space</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Space">
        <Paragraph>
          Our space scale is based on a 16px unit. When creating a button row,
          margin sizes can range from one-eighth (2px) to four full spaces.
        </Paragraph>
        <ComponentPreview
          name="ButtonType"
          layout="split"
          previewData={buttonRowPreviewData.spaces}>
          <ButtonRow>
            <Button type="subtle" text="Edit" />
            <Button type="primary" text="Save" />
          </ButtonRow>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Button Types</SectionSubhead>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="mix and match button sizes, styles and types in a single row."
            img="buttonrow-buttontypes-dont"
          />
          <DontDoItem
            type="do"
            text="make the buttons as similar as possible—this includes text length."
            img="buttonrow-buttontypes-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
